import React from 'react'
import { Waypoint } from 'react-waypoint'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Members from '../components/members'
import AboutSection from '../components/aboutsection'
import Footer from '../components/footer'
import Banner from '../assets/img/banner.jpg'
import SectionImg1 from '../assets/img/Uitgeverij.png'
import SectionImg2 from '../assets/img/FM.jpg'
import SectionImg3 from '../assets/img/team.jpg'
import SectionBg1 from '../assets/img/sb1.jpg'
import SectionBg2 from '../assets/img/sb2.jpg'

// import Video from "../components/video"
import VideoSrc from "../assets/video/w4m-vid.mp4"

const AboutPage = () => {


    return (
        <Layout>
            <Intro title="Wie zijn wij" text="Work4Media is al ruim 25 jaar actief in de bouwsector. Daarmee hebben we een schat aan kennis en ervaring op zak waar we bij al onze werkzaamheden uit kunnen putten. Lees hieronder wat we precies voor je kunnen betekenen." />
            <div className="banner">
                <div className="banner-overlay"></div>
                <div className="video-container">
                    {/* <video loop autoPlay muted>
                        <source src={VideoSrc} type="video/mp4" />
                    </video> */}
                    <div dangerouslySetInnerHTML={{
                        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${VideoSrc}"
        />,
      ` }}></div>
                </div>
            </div>
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12 col-md-8 off-md-2">
                        <div className="about-intro" id="js-about-intro">
                            <Waypoint bottomOffset="200px" onEnter={() => {
                                let currentElement = document.getElementById("js-about-intro");
                                currentElement.classList.add('show');
                            }}
                            />
                            <h3>Over ons</h3>
                            <p className="flat-text">We zijn als uitgeverij ooit gestart met de uitgifte van het kennismagazine Industriebouw. Vanuit een passie voor bouwen én vanuit de overtuiging dat we echt iets bijzonders te bieden hebben. Kennelijk werkt het want inmiddels heeft de bouwsector ons ook gevonden via onze social media kanalen en hebben we onze portfolio uitgebreid. Onder de koepel Work4Media hebben we bovendien een fullservice mediabureau in het leven geroepen om o.a. bouwbedrijven op het gebied van social media te ontlasten.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-sections">
                <AboutSection
                    img={SectionImg1}
                    background={SectionBg2}
                    title="Uitgeverij"
                    text="We durven te stellen dat de kennismagazines van Work4Media een bijzondere plaats innemen in het bladenlandschap in Nederland. Al onze magazines zijn toegespitst op specifieke segmenten én doelgroepen en hebben één ding met elkaar gemeen; ze worden allemaal gemaakt door redacteuren en specialisten met een enorme liefde voor bouwen. In elke uitgave geven we onze lezers een royale blik voor en achter de schermen bij actuele, spraakmakende bouwprojecten, zowel klein als groot. Daarmee vormen onze magazines een belangrijke bron van kennis en informatie voor iedereen die beroepsmatig betrokken is binnen de bouwsector."
                    text2="Ons doel is lezers op ideeën te brengen en de aandacht te richten op bouwkundige zaken die normaal gesproken onbelicht blijven. We duiken in de inhoud en maken inzichtelijk welke keuzes vóór en tijdens een bouwproces zijn gemaakt door initiatiefnemers en bouwers. En dat doen we ook nog eens in de vorm van aansprekende, inhoudelijk sterke teksten. Work4Media combineert daarmee bouwkundige kennis en vermaak. Infotainment noemen we dat."
                    quote="‘Verrassen en inspireren op functioneel, esthetisch én bouwkundig terrein’"
                    id="1" />
                <AboutSection
                    img={SectionImg2}
                    // background={SectionBg2}
                    title="Fullservice mediabureau"
                    text="Onze magazines geven initiatiefnemers, bouwbedrijven en onderaannemers de kans om te laten zien waar ze goed in zijn. Vanuit ons fullservice mediabureau doen we daar desgewenst nog een schepje bovenop. We beheren je social media en creëren inspirerende content – ook in de vorm van foto’s en filmpjes – die je online zichtbaarheid aanzienlijk vergroot. Samen kijken we waar je precies behoefte aan hebt, waarna we de content specifiek afstemmen op jouw doelgroep. Door actief te zijn en te blijven, bereik je meer mensen en krijg je meer volgers. Op termijn kunnen hier weer nieuwe opdrachten uit voortkomen. Daar gaat het natuurlijk uiteindelijk om. Wij nemen je op dit gebied met plezier alles uit handen zodat jij je als ondernemer kunt focussen op je corebusiness."
                    quote="‘Online ontzorgen van a tot z’"
                    id="2" />
                <AboutSection
                    img={SectionImg3}
                    background={SectionBg1}
                    title="Een ervaren team dat deuren opent"
                    text="Work4Media werkt met een enthousiast en slagvaardig team redacteuren en vakmensen. Ervaren specialisten met een omvangrijk relatienetwerk in de bouwwereld. Elk van hen heeft zijn of haar sporen in de sector verdiend. Onze specialisten zijn op de hoogte van de nieuwste trends, bouwmethoden en wetgeving. Ze bezoeken regelmatig projecten in ontwikkeling, toonaangevende marktpartijen, seminars en bijeenkomsten. Daardoor is hun kennis altijd up-to-date. Daarnaast is hun persoonlijke aanpak een belangrijke kracht. Ze doen hun uiterste best om ervoor te zorgen dat iedereen wordt gehoord, gezien en begrepen."
                    quote="‘Wij kennen bouwend Nederland en bouwend Nederland kent ons’"
                    id="3" />
            </div>
            <Members />
            <Footer />
        </Layout>
    )
}

export default AboutPage;
