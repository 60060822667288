import React from 'react'
import { Waypoint } from 'react-waypoint'

const aboutSection = (props) => {
    return (
        <div className="section-wrapper" style={{ backgroundImage: `url(${props.background})` }} id={props.id}>
            <Waypoint bottomOffset="200px" onEnter={() => {
                let currentElement = document.getElementById(props.id);
                currentElement.classList.add('show');
            }}
            />
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12 col-md-4 off-md-1" >
                        <div className="img-wrapper" style={{ backgroundImage: `url(${props.img})` }}>
                            {/* <img src={props.img}></img> */}
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <h4>{props.title}</h4>
                        <p className="flat-text">{props.text}</p>
                        <p className="flat-text">{props.text2}</p>
                        <span className="quote">{props.quote}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default aboutSection;
