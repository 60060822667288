import React from 'react'
import { useStaticQuery } from "gatsby"
import { Waypoint } from 'react-waypoint'

import TwitterBlack from "../assets/img/icons/twitter-b.svg"
import LinkedInBlack from "../assets/img/icons/linkedin-b.svg"
import GoogleBlack from "../assets/img/icons/google-b.svg"

const Members = () => {

    const data = useStaticQuery(graphql`
    query {
      allContentfulTeamMembers(sort: {fields: orderId, order: ASC}) {
        edges {
          node {
            name
            role
            quote
            twitter
            linkedin
            email
            image {
                file {
                  url
                }
              }
          }
        }
      }
    }
  `)

    return (
        <div className="inner">
            <div className="members-wrapper" id="js-members-wrapper">
                <Waypoint bottomOffset="200px" onEnter={() => {
                    let currentElement = document.getElementById("js-members-wrapper");
                    currentElement.classList.add('show');
                }} />
                <div className="row">
                    <div className="col-xs-12 col-md-8 off-md-2">
                        <h3>Maak kennis met ons team</h3>
                        <p className="flat-text">Wij houden het contact met onze klanten graag informeel en laagdrempelig. We staan altijd voor je klaar en zijn te bereiken via mail of telefoon. Het praat makkelijker als je het gezicht achter onze namen kent. Vandaar dat we ons hier beneden kort aan je voorstellen.</p>
                    </div>
                </div>
                <div className="row">
                    {data.allContentfulTeamMembers.edges.map((edge, i) => {
                        let mailToValue = "mailto:" + edge.node.email
                        return (
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 card-wrapper" key={i}>
                                <div className="card">
                                    <div className="card-overlay"></div>
                                    <div className="img-wrapper">
                                        <img src={edge.node.image.file.url} alt=""></img>
                                    </div>
                                    <h5>{edge.node.name}</h5>
                                    <span className="role">{edge.node.role}</span>
                                    <p className="member-quote">{edge.node.quote}</p>
                                    <div className="social-wrapper">
                                        {edge.node.twitter ? <a href={edge.node.twitter}><img src={TwitterBlack} alt=""/></a> : null}
                                        {edge.node.linkedin ? <a href={edge.node.linkedin}><img src={LinkedInBlack} alt=""/></a> : null}
                                        {edge.node.email ? <a href={mailToValue}><img src={GoogleBlack} alt=""/></a> : null}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}

export default Members;
